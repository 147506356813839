<template>
  <v-app>
    <v-app-bar app dark v-if="!$vuetify.breakpoint.mdAndUp">
      <v-spacer></v-spacer>
      <v-icon @click="showNav = !showNav"> mdi-menu </v-icon>
    </v-app-bar>

    <v-navigation-drawer
      app
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="showNav"
      dark
    >
      <v-simple-table>
        <tbody>
          <tr
            v-for="(tab, index) in tabs"
            :key="`nav-tab-${index}`"
            :class="index == selectedTab ? 'active' : ''"
            style="cursor: pointer"
            @click="goPath(tab.path)"
          >
            <td>
              {{ tab.name }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="snack.visible" :color="snack.color">
      {{ snack.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "App",

  data: () => ({
    showNav: false,
  }),

  computed: {
    ...mapState(["snack"]),
    tabs() {
      return this.$router.options.routes.filter((route) => route.meta.visible);
    },
    selectedTab() {
      const list = this.tabs.map(({ path }) => path);
      return list.indexOf(this.$route.path);
    },
  },

  methods: {
    ...mapActions(["getContents"]),
    goPath(path) {
      if (this.$route.path != path) this.$router.push(path);
      if (this.$route.meta.remote) this.getContents(path);
    },
  },

  mounted() {
    this.getContents(this.$route.path);
  },
};
</script>
