<template>
  <div>
    <v-container>
      <v-row>
        <v-col align="center">
          <v-btn href="/api/get-contacts"> Download Contacts (ZIP) </v-btn>
        </v-col>
        <v-col align="center">
          <v-btn href="/api/get-mailing-list"> Download Mailing List (CSV) </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
